.search_box{
  position: relative;
  min-height: 25px;
  line-height: 25px;
  padding: 0 5px;
}
.search_input{
  border:1px solid #ccc;
  padding:0px 2px;
  font-size: 16px;
}