@tailwind base;
@tailwind components;
@tailwind utilities;

@import './css/base.css';
@import './css/layout.css';
@import './css/header.css';
@import './css/gnb.css';
@import './css/snb.css';
@import './css/btn.css';
@import './css/popup.css';
@import './css/write.css';
@import './css/search.css';
@import './css/paging.css';
@import './css/plan/plan_math.css';
@import './css/print.css';