.matter_page_title_box{
  position: relative;
  width: 100%;
}
.matter_page_title_box table{
  width: 100%;
  border-collapse: collapse;
}
.matter_page_title_box table td{
  border:1px solid #000000;
  height: 60px;
  padding: 5px;
  text-align: center;
}
.matter_page_title_box .matter_page_title_wrap{
  text-align: left;
  font-size: 16px;
}
.matter_page_qrcode_canvas{
  height:125px;
  width: 125px;
}
.matter_page_title_box .matter_page_title{
  font-size: 22px;
}
.matter_page_title_box .matter_page_sub_title{
  font-size: 16px;
}

.matter_page_wrap{
  display:flex;
  gap:15px;
  padding-top: 20px;
  justify-content: space-evenly;
}
.matter_page_box{
  width: 48%;
  min-height: 23cm;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:15px;
  padding: 0 10px;
}

.matter_wrap{
  display: inline-block;
  position: relative;
  padding-left:35px;
  margin:0 auto;
}
.matter_wrap.matter_wrap_align_left{
  margin:0;
}
.matter_idx_num{
  position: absolute;
  top:5px;
  left:0px;
  font-size: 12px;
  display: inline-block;
  width: 23px;
  height: 23px;
  line-height: 20px;
  border:1px solid #000000;
  border-radius: 15px;
  font-weight: bold;
  text-align: center;
}
.matter_base_table{
  border-collapse: collapse;
  border: 2px solid #333;
  width: 100%;
  max-width: 100%;
}
.matter_base_table td{
  min-width: 60px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #444;
  text-align: center;
}
.matter_base_table td span{
  font-size: 22px;
}

.matter_inline_table{
  border-collapse: collapse;
  max-width: 100%;
  margin-bottom: 20px;
}
.matter_inline_table td{
  min-width: 20px;
  height: 28px;
  line-height: 28px;
  text-align: center;
}
.matter_inline_table td span{
  font-size: 22px;
}

.matter_height_table{
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
}
.matter_height_table td{
  vertical-align: top;
  width: 20px;
  height: 20px;
  border: none;
  text-align: center;
  font-size: 22px;
  line-height: 22px;
}
.matter_height_table.matter_dotted_table td{
  border:1px dotted #777777;
}
.matter_height_table .tr_top_border{
  border-top:2px solid #000;
}
.matter_height_table td.td_top_border{
  border-top:2px solid #000;
}
.matter_height_table .divide_left_border{
  position: absolute;
  top: -4px;
  left: 0px;
  height: 100%;
  font-size: 32px;
  line-height: 25px;
  text-indent: -2px;
}

.matter_fraction_table{
  border-collapse: collapse;
  display: inline-block;
  zoom: 1;
  vertical-align: middle;
}
.matter_fraction_table td{
  border:0px;
  text-align: center;
  padding: 0 5px;
  font-size: 21px;
  line-height: 23px;
}
.matter_fraction_table td span{
  font-size: 21px;
}
.matter_fraction_table td.fraction-natural{
  padding-right:3px;
}
.matter_fraction_table td.fraction-denominator{
  border-top:1px solid #000;
}
.matter_wrap .equal_span{
  font-size: 22px;
  padding:0px 8px;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.matter_wrap .matter_span{
  font-size: 22px;
  color: #000;
  display: inline-block;
  width: 28px;
  height: 22px;
  line-height: 22px;
}
.matter_wrap .matter_up_span{
  font-size: 15px;
}
.matter_wrap .answer_span{
  color: #0064CD;
}
.matter_wrap .answer_box{
  border:2px solid #000;
  text-align: center;
  min-width: 25px;
  font-size: 22px;
}